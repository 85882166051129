var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.data.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "mr-3",
                    staticStyle: { "font-size": "1.6rem" },
                  },
                  [_c("i", { staticClass: "flaticon2-calendar-2" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return [_c("MarkingPeriodSubHeaderMenu")]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light":
                        _vm.data.saving,
                    },
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.saveValidMarks.apply(null, arguments)
                      },
                    },
                  },
                  [
                    !_vm.data.saving
                      ? _c("i", { staticClass: "fa fa-save" })
                      : _vm._e(),
                    _vm._v(" Save Valid Marks "),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "kt-container kt-grid__item kt-grid__item--fluid" },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "kt-grid__item kt-grid__item--fluid kt-app__content",
                },
                [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__body" }, [
                      _c("div", { staticClass: "tab-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane active",
                            staticStyle: { "overflow-x": "hidden" },
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "row pb-3 bottom-border" },
                                [
                                  _c("div", { staticClass: "col-lg-6" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-input-icon kt-input-icon--left",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.data.filter,
                                              expression: "data.filter",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Filter...",
                                          },
                                          domProps: { value: _vm.data.filter },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.data,
                                                "filter",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._m(0),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-muted pt-3" },
                                      [_vm._v(" Filter by 'Mark' ")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-lg-6 pt-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group pull-right mr-4",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "kt-checkbox-inline",
                                            staticStyle: {
                                              "padding-bottom": "2px",
                                            },
                                          },
                                          [
                                            _c(
                                              "label",
                                              { staticClass: "kt-checkbox" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.data.passing,
                                                      expression:
                                                        "data.passing",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.data.passing
                                                    )
                                                      ? _vm._i(
                                                          _vm.data.passing,
                                                          null
                                                        ) > -1
                                                      : _vm.data.passing,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.data.passing,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "passing",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "passing",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.data,
                                                          "passing",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" Pass "),
                                                _c("span"),
                                              ]
                                            ),
                                            _c(
                                              "label",
                                              { staticClass: "kt-checkbox" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.data.failing,
                                                      expression:
                                                        "data.failing",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.data.failing
                                                    )
                                                      ? _vm._i(
                                                          _vm.data.failing,
                                                          null
                                                        ) > -1
                                                      : _vm.data.failing,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.data.failing,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "failing",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "failing",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.data,
                                                          "failing",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" Fail "),
                                                _c("span"),
                                              ]
                                            ),
                                            _c(
                                              "label",
                                              { staticClass: "kt-checkbox" },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.data.special,
                                                      expression:
                                                        "data.special",
                                                    },
                                                  ],
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.data.special
                                                    )
                                                      ? _vm._i(
                                                          _vm.data.special,
                                                          null
                                                        ) > -1
                                                      : _vm.data.special,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.data.special,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "special",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.data,
                                                              "special",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.data,
                                                          "special",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" Special "),
                                                _c("span"),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-muted pt-3 pull-right",
                                          },
                                          [_vm._v(" Filter by 'Type' ")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "table",
                                { staticClass: "table table-striped" },
                                [
                                  _vm._m(1),
                                  _c(
                                    "tbody",
                                    { staticClass: "kt-datatable__body" },
                                    _vm._l(
                                      _vm.validMarks(),
                                      function (validMark) {
                                        return _c(
                                          "tr",
                                          {
                                            key: `${validMark.markingPeriodValidMarkId}`,
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "padded" },
                                              [
                                                _c("span", {
                                                  class: `colorSwatch ${validMark.passing}`,
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(validMark.mark) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "padded" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        validMark.markDescription
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "numeric padded" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "numeric" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          validMark.numericEquivalent ||
                                                            "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "padded" },
                                              [
                                                _c("span", [
                                                  validMark.isCourseMark
                                                    ? _c("i", {
                                                        staticClass:
                                                          "la la-check-circle kt-font-success",
                                                        staticStyle: {
                                                          "font-size": "1.5rem",
                                                        },
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "la la-circle kt-font-warning",
                                                        staticStyle: {
                                                          "font-size": "1.6rem",
                                                        },
                                                      }),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "padded" },
                                              [
                                                _c("span", [
                                                  validMark.isExamMark
                                                    ? _c("i", {
                                                        staticClass:
                                                          "la la-check-circle kt-font-success",
                                                        staticStyle: {
                                                          "font-size": "1.5rem",
                                                        },
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "la la-circle kt-font-warning",
                                                        staticStyle: {
                                                          "font-size": "1.6rem",
                                                        },
                                                      }),
                                                ]),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "col-11 kt-switch kt-switch--icon kt-switch--sm",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass: "notPadded",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              validMark.isReportCards,
                                                            expression:
                                                              "validMark.isReportCards",
                                                          },
                                                        ],
                                                        attrs: {
                                                          id: validMark.markingPeriodValidMarkId,
                                                          type: "checkbox",
                                                          name: "isReportCards",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            validMark.isReportCards,
                                                          checked:
                                                            Array.isArray(
                                                              validMark.isReportCards
                                                            )
                                                              ? _vm._i(
                                                                  validMark.isReportCards,
                                                                  null
                                                                ) > -1
                                                              : validMark.isReportCards,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$a =
                                                                  validMark.isReportCards,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      validMark,
                                                                      "isReportCards",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      validMark,
                                                                      "isReportCards",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  validMark,
                                                                  "isReportCards",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                            function ($event) {
                                                              return _vm.handleToggleAllowReportCard(
                                                                $event
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      }),
                                                      _c("span"),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--left" },
      [_c("span", [_c("i", { staticClass: "la la-search" })])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", { staticClass: "kt-datatable__head" }, [
      _c("tr", [
        _c("th", { attrs: { id: "validMarkId", width: "15%" } }, [
          _c("span", [_vm._v("Mark")]),
        ]),
        _c("th", { attrs: { id: "validMarkDescirption" } }, [
          _c("span", [_vm._v("Description")]),
        ]),
        _c("th", { attrs: { id: "validMarkNumericValue", width: "20%" } }, [
          _c("span", [_vm._v("Numeric Equivalent")]),
        ]),
        _c("th", { attrs: { id: "validMarkCourse" } }, [
          _c("span", [_vm._v("Courses")]),
        ]),
        _c("th", { attrs: { id: "validMarkExam" } }, [
          _c("span", [_vm._v("Exams")]),
        ]),
        _c("th", { attrs: { id: "toggleReportCard", width: "15%" } }, [
          _c("span", [_vm._v("Report Card")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }