<template>
<div :key="`key_${data.key}`">
    <GeneralSubHeader>
        <template #title>
            <span
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                <i class="flaticon2-calendar-2" />
            </span>
        </template>
        <template #left-buttons>
            <MarkingPeriodSubHeaderMenu />
        </template>
        <template #buttons>
            <button
                type="button"
                class="btn btn-primary"
                :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': data.saving }"
                @click.stop.prevent="saveValidMarks"
            >
                <i
                    v-if="!data.saving"
                    class="fa fa-save"
                />
                Save Valid Marks
            </button>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-grid__item kt-grid__item--fluid">
        <div class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
            <div class="kt-grid__item kt-grid__item--fluid kt-app__content">
                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div class="tab-content">
                            <div
                                class="tab-pane active"
                                style="overflow-x: hidden"
                            >
                                <div>
                                    <div class="row pb-3 bottom-border">
                                        <div class="col-lg-6">
                                            <div class="kt-input-icon kt-input-icon--left">
                                                <input
                                                    v-model="data.filter"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Filter..."
                                                >
                                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                    <span>
                                                        <i class="la la-search" />
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="text-muted pt-3">
                                                Filter by 'Mark'
                                            </div>
                                        </div>
                                        <div class="col-lg-6 pt-1">
                                            <div class="form-group pull-right mr-4">
                                                <div
                                                    class="kt-checkbox-inline"
                                                    style="padding-bottom: 2px"
                                                >
                                                    <label class="kt-checkbox">
                                                        <input
                                                            v-model="data.passing"
                                                            type="checkbox"
                                                        > Pass
                                                        <span />
                                                    </label>
                                                    <label class="kt-checkbox">
                                                        <input
                                                            v-model="data.failing"
                                                            type="checkbox"
                                                        > Fail
                                                        <span />
                                                    </label>
                                                    <label class="kt-checkbox">
                                                        <input
                                                            v-model="data.special"
                                                            type="checkbox"
                                                        > Special
                                                        <span />
                                                    </label>
                                                </div>
                                                <div class="text-muted pt-3 pull-right">
                                                    Filter by 'Type'
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="table table-striped">
                                        <thead class="kt-datatable__head">
                                            <tr>
                                                <th
                                                    id="validMarkId"
                                                    width="15%"
                                                >
                                                    <span>Mark</span>
                                                </th>
                                                <th id="validMarkDescirption">
                                                    <span>Description</span>
                                                </th>
                                                <th
                                                    id="validMarkNumericValue"
                                                    width="20%"
                                                >
                                                    <span>Numeric Equivalent</span>
                                                </th>
                                                <th id="validMarkCourse">
                                                    <span>Courses</span>
                                                </th>
                                                <th id="validMarkExam">
                                                    <span>Exams</span>
                                                </th>
                                                <th
                                                    id="toggleReportCard"
                                                    width="15%"
                                                >
                                                    <span>Report Card</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="kt-datatable__body">
                                            <tr
                                                v-for="validMark in validMarks()"
                                                :key="`${validMark.markingPeriodValidMarkId}`"
                                            >
                                                <td class="padded">
                                                    <span :class="`colorSwatch ${validMark.passing}`" />
                                                    <span>
                                                        {{ validMark.mark }}
                                                    </span>
                                                </td>
                                                <td class="padded">
                                                    <span>
                                                        {{ validMark.markDescription }}
                                                    </span>
                                                </td>
                                                <td class="numeric padded">
                                                    <span class="numeric">
                                                        {{ validMark.numericEquivalent || 'N/A' }}
                                                    </span>
                                                </td>
                                                <td class="padded">
                                                    <span>
                                                        <i
                                                            v-if="validMark.isCourseMark"
                                                            class="la la-check-circle kt-font-success"
                                                            style="font-size: 1.5rem"
                                                        />
                                                        <i
                                                            v-else
                                                            class="la la-circle kt-font-warning"
                                                            style="font-size: 1.6rem"
                                                        />
                                                    </span>
                                                </td>
                                                <td class="padded">
                                                    <span>
                                                        <i
                                                            v-if="validMark.isExamMark"
                                                            class="la la-check-circle kt-font-success"
                                                            style="font-size: 1.5rem"
                                                        />
                                                        <i
                                                            v-else
                                                            class="la la-circle kt-font-warning"
                                                            style="font-size: 1.6rem"
                                                        />
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="col-11 kt-switch kt-switch--icon kt-switch--sm">
                                                        <label class="notPadded">
                                                            <input
                                                                :id="validMark.markingPeriodValidMarkId"
                                                                v-model="validMark.isReportCards"
                                                                type="checkbox"
                                                                :checked="validMark.isReportCards"
                                                                name="isReportCards"
                                                                @change="handleToggleAllowReportCard($event)"
                                                            >
                                                            <span />
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import network from '../lib/network';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import MarkingPeriodSubHeaderMenu from '../components/MarkingPeriodSubHeaderMenu.vue';

export default {
    name: 'ValidMarks',
    components: {
        MarkingPeriodSubHeaderMenu,
        GeneralSubHeader,
    },
    data() {
        return {
            data: {
                key: 0,
                allValidMarks: [],
                dirtyValidMarkIds: [],
                filter: '',
                passing: true,
                failing: true,
                special: true,
                saving: false,
            },
        };
    },
    computed: mapState({
        user: (state) => state.user,
        schoolTermId: (state) => state.user.school.schoolTermId,
    }),
    watch: {
    },
    mounted() {
        this.$nextTick(() => {
            this.populate();
        });
    },
    methods: {
        populate() {
            const v = this;

            async.auto({
                validMarks(next) {
                    v.getValidMarks(v, next);
                },
            }, 3, (err) => {
                if (err) return v.showError('Error populating valid marks');
            });
        },
        getValidMarks(v, callback) {
            if (!v.user) return;

            network.validMarks.getValidMarks(v.user, (err, res) => {
                if (err) return callback(err);
                v.data.allValidMarks = res.validMarks;
                callback(null, res);
            });
        },
        validMarks() {
            const v = this;
            const validMarks = v.data.allValidMarks.filter((validMark) => {
                const includeByBoolean = (v.data.passing && validMark.passing == true)
                    || (v.data.failing && validMark.passing == false)
                    || (v.data.special && validMark.passing == null);
                const includeBySeach = validMark.mark.toLowerCase().includes(v.data.filter)
                    || validMark.markDescription.toLowerCase().includes(v.data.filter);

                return includeByBoolean && includeBySeach;
            });

            return validMarks;
        },
        handleToggleAllowReportCard(event) {
            const v = this;
            v.data.dirtyValidMarkIds.push(event.target.id);
        },
        saveValidMarks() {
            const v = this;
            if (v.data.saving) return;
            v.data.saving = true;

            const dirtyValidMarks = v.data.dirtyValidMarkIds.map((dirtyValidMarkId) => {
                const dirtyValidMark = v.data.allValidMarks.find((validMark) => validMark.markingPeriodValidMarkId == dirtyValidMarkId);
                return dirtyValidMark;
            });

            let success = true;

            dirtyValidMarks.map((dirtyValidMark) => {
                network.validMarks.setValidMarkReportCardStatus(v.user, dirtyValidMark, (err, res) => {
                    if (err) {
                        success = false;
                        alert('Something went wrong', 'Please try again, or call support for assistance!');
                    }
                });
            });

            if (success) v.data.dirtValidMarks = [];

            v.data.saving = false;
        },
    },
};

</script>

<style scoped>
span.colorSwatch {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 2px;
    vertical-align: middle;
    margin-right: 1rem;
    margin-top: -0.1rem;
}

.colorSwatch.false {
    background: #dc3545;
    color: white;
}

.colorSwatch.true {
    background: #28a745;
    color: white;
}

.colorSwatch.null {
    background: grey;
    color: white;
}

td.numeric {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

span.numeric {
    align-self: center;
    width: 40px;
}

span.notPadded {
    margin-bottom: 0px;
}

td.padded {
    padding-top: 14px;
}

label.notPadded {
    margin-bottom: 0px;
}

.bottom-border {
    border-bottom: 1px solid #ebedf2;
}

.sg-portlet-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px;
    height: 59px;
    border-top: 1px solid #ebedf2;
}
</style>
